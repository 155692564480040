import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {fireAuth} from '../firebase';
import * as ROUTES from '../constants/routes';
import FormContainer from "../components/FormContainer";

const PasswordForgetPage = () => (
  <FormContainer>
    <h1>Esqueci a senha</h1>
    <PasswordForgetForm/>
  </FormContainer>
);

const PasswordForgetFormBase = () => {
  const [formFields, setFormFields] = useState({});

  const onSubmit = async event => {
    event.preventDefault();
    try {
      await fireAuth().sendPasswordResetEmail(formFields.email);
      setFormFields({});
    } catch (passwordResetError) {
      setFormFields({...formFields, error: passwordResetError});
    }
  };

  const onChange = event => {
    setFormFields({...formFields, [event.target.name]: event.target.value});
  };

  const isInvalid = formFields.email === '';

  return (
    <form onSubmit={onSubmit}>
      <input
        name="email"
        value={formFields.email}
        onChange={onChange}
        type="text"
        placeholder="E-mail"
      />
      <button disabled={isInvalid} type="submit">
        Resetar minha senha
      </button>
      {formFields.error && <p>{formFields.error.message}</p>}
    </form>
  );

};
const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Esqueci a senha.</Link>
  </p>
);
export default PasswordForgetPage;
const PasswordForgetForm = PasswordForgetFormBase;
export {PasswordForgetForm, PasswordForgetLink};