import React from 'react';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import VerticalCard from "./VerticalCard";

const Container = styled.div`
  background-color: #e0e0e0;
`;

const CardContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 20px;
  padding: 0;
`;

const CardList = props => {
  const {clinics} = props;
  return (
    <Container>
      <PerfectScrollbar>
        <CardContainer>
          {clinics.map((clinic, index) => <VerticalCard key={index} clinic={clinic} />)}
        </CardContainer>
      </PerfectScrollbar>
    </Container>
  )
};


export default CardList;
