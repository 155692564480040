export const DAYS = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado'
];

export const ABBREVIATED_DAYS = [
  'dom',
  'seg',
  'ter',
  'qua',
  'qui',
  'sex',
  'sáb'
];