import React, {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import * as ROUTES from '../constants/routes';

const NavigationNonAuthList = () => (
  <Fragment>
    <ul>
      <li>
        <Link to={ROUTES.SIGN_IN}>Login</Link>
      </li>
    </ul>
  </Fragment>
);

export default withRouter(NavigationNonAuthList);
