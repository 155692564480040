import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import {ABBREVIATED_DAYS} from "../constants/utils";
import DefaultModal from "./DefaultModal";
import CardItemDetail from "./CardItemDetail";

const Card = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: #fcfcfc;
  margin: 0 25px 25px 25px;
  transition: 0.4s all;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
  padding: 0 2.5rem;
`;

const TitleBox = styled.div`
  color: #2980b9;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const InfoButton = styled.button`
  background: transparent;
  border-style: solid;
  border-color: #2980b9;
  border-width: 1px;
  border-radius: 5px;
  :hover, :active {
    background: #2980b9;
    color: #FFFFFF;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const Avatar = styled.div`
  position: relative;
  width: 100px;
  height: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  img{
    width: 100px;
    height: auto;
  }
`;

const TextBox = styled.div`
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 10px;

  p {
    text-align: left;
    color: #aaa;
  }
`;

const VerticalCard = (props) => {
  const [showModal, setShowModal] = useState(false);

  const {clinic} = props;
  const {name, phones, businessHours, price} = clinic;

  const images = clinic.images? clinic.images : ['/images/clinic_default.png'];

  const genBusinessHoursText = () => {
    return businessHours.map((businessHour, index) => {
      const {dayStart, dayEnd, hourStart, hourEnd} = businessHour;
      const brAtEnd = (businessHours.length === index) ? '' : <br/>;

      if (dayStart === dayEnd) {
        return (
          <Fragment key={index}>
            {` • De ${ABBREVIATED_DAYS[dayStart]} das ${hourStart} às ${hourEnd}.`}
            {brAtEnd}
          </Fragment>
        )
      } else {
        return (
          <Fragment key={index}>
            {` • De ${ABBREVIATED_DAYS[dayStart]} à ${ABBREVIATED_DAYS[dayEnd]} das ${hourStart} às ${hourEnd}.`}
            {brAtEnd}
          </Fragment>
        )
      }
    })
  };

  const openModalHandler = () => {
    setShowModal(true);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <DefaultModal
        isOpen={showModal}
        onRequestClose={closeModalHandler}
        title={name}
        children={<CardItemDetail clinic={clinic} images={images} genBusinessHoursText={genBusinessHoursText} closeModalHandler={closeModalHandler}/>}
      />

      <Card>
        <TitleBox>
          <h1>{name}</h1>
          <Spacer/>
          <InfoButton onClick={openModalHandler}>+ info</InfoButton>
        </TitleBox>
        <Content>
          <Avatar> <img src={images[0]} alt={name}/> </Avatar>
          <TextBox>
            <p>
              <b>Telefone:</b> {phones.map(phone => (`(${phone.ddd}) ${phone.number}. `))} <br/>
              <b>Horário de atendimento:</b> <br/> {genBusinessHoursText()}
              <b>Valor consulta:</b> {price}</p>
          </TextBox>
        </Content>
      </Card>
    </Fragment>
  )
};

export default VerticalCard;
