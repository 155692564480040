import React, {useEffect, useRef} from 'react'
import {functions, isEqual, omit} from 'lodash'
import useGeolocation from 'react-hook-geolocation'

const Map = ({options, className, markers}) => {
  const ref = useRef();
  const geolocation = useGeolocation();

  if (geolocation && !geolocation.error) {
    options.center.lat = geolocation.latitude || options.center.lat;
    options.center.lng = geolocation.longitude || options.center.lng;
  }

  useEffect(() => {
    const onLoad = () => {
      const map = new window.google.maps.Map(ref.current, options);
      if (typeof markers === 'object') {
        markers.forEach(item => {
          const marker = new window.google.maps.Marker({
            map,
            position: {
              lat: item.geopoint.latitude,
              lng: item.geopoint.longitude,
            },
            // label: item.name,
            title: item.locality,
          });
          marker.addListener(`click`, () => {
            console.log(item)
          })
        });
      }
    };

    if (!window.google) {
      const script = document.createElement(`script`);
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=` +
        `AIzaSyBJEDDU3a2LZkkJ7pf1AYkMw6P2vU-jA1o`;
      document.head.append(script);
      script.addEventListener(`load`, onLoad);
      return () => script.removeEventListener(`load`, onLoad);
    } else onLoad()
  }, [markers, options]);

  return (
    <div
      style={{height: `100%`, borderRadius: `0`}}
      {...{ref, className}}
    />
  )
};

const shouldNotUpdate = (props, nextProps) => {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)];
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs));
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every(fn => props[fn].toString() === nextProps[fn].toString());
  return noPropChange && noFuncChange;
};

// const geolocation = useGeolocation();
Map.defaultProps = {
  options: {
    center: {
      lat: -22.95349,
      lng: -43.1739373,
    },
    zoom: 14,
    // styles: [
    //   {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
    //   {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
    //   {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
    //   {
    //     featureType: 'administrative.locality',
    //     elementType: 'labels.text.fill',
    //     stylers: [{color: '#d59563'}]
    //   },
    //   {
    //     featureType: 'poi',
    //     elementType: 'labels.text.fill',
    //     stylers: [{color: '#d59563'}]
    //   },
    //   {
    //     featureType: 'poi.park',
    //     elementType: 'geometry',
    //     stylers: [{color: '#263c3f'}]
    //   },
    //   {
    //     featureType: 'poi.park',
    //     elementType: 'labels.text.fill',
    //     stylers: [{color: '#6b9a76'}]
    //   },
    //   {
    //     featureType: 'road',
    //     elementType: 'geometry',
    //     stylers: [{color: '#38414e'}]
    //   },
    //   {
    //     featureType: 'road',
    //     elementType: 'geometry.stroke',
    //     stylers: [{color: '#212a37'}]
    //   },
    //   {
    //     featureType: 'road',
    //     elementType: 'labels.text.fill',
    //     stylers: [{color: '#9ca5b3'}]
    //   },
    //   {
    //     featureType: 'road.highway',
    //     elementType: 'geometry',
    //     stylers: [{color: '#746855'}]
    //   },
    //   {
    //     featureType: 'road.highway',
    //     elementType: 'geometry.stroke',
    //     stylers: [{color: '#1f2835'}]
    //   },
    //   {
    //     featureType: 'road.highway',
    //     elementType: 'labels.text.fill',
    //     stylers: [{color: '#f3d19c'}]
    //   },
    //   {
    //     featureType: 'transit',
    //     elementType: 'geometry',
    //     stylers: [{color: '#2f3948'}]
    //   },
    //   {
    //     featureType: 'transit.station',
    //     elementType: 'labels.text.fill',
    //     stylers: [{color: '#d59563'}]
    //   },
    //   {
    //     featureType: 'water',
    //     elementType: 'geometry',
    //     stylers: [{color: '#17263c'}]
    //   },
    //   {
    //     featureType: 'water',
    //     elementType: 'labels.text.fill',
    //     stylers: [{color: '#515c6d'}]
    //   },
    //   {
    //     featureType: 'water',
    //     elementType: 'labels.text.stroke',
    //     stylers: [{color: '#17263c'}]
    //   }
    // ]
  },
};

export default React.memo(Map, shouldNotUpdate)