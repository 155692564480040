import React from 'react';
import styled from 'styled-components';

const ToggleButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 20px;
  width: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  
  :focus {
    outline: none;
  }
`;

const ToggleButtonLine = styled.div`
  width: 25px;
  height: 2px;
  background: #FFFFFF;
`;

const DrawerToggleButton = props => {
  const {click} = props;
  return (
    <ToggleButton onClick={click}>
      <ToggleButtonLine/>
      <ToggleButtonLine/>
      <ToggleButtonLine/>
    </ToggleButton>
  )
};

export default DrawerToggleButton;