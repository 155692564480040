import firebase from 'firebase';
import '@firebase/firestore';

export const fireAuth = firebase.auth;
export const fireStore = firebase.firestore;
export const fireStorage = firebase.storage;

// Initialize Firebase
export const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBJEDDU3a2LZkkJ7pf1AYkMw6P2vU-jA1o",
    authDomain: "sua-vida-importa.firebaseapp.com",
    databaseURL: "https://sua-vida-importa.firebaseio.com",
    projectId: "sua-vida-importa",
    storageBucket: "sua-vida-importa.appspot.com",
    messagingSenderId: "806242977935",
    appId: "1:806242977935:web:e088b188facd438fec08fa",
    measurementId: "G-R00G87NZ5Y"
  };

  // const temp = {
  //   apiKey: process.env.REACT_APP_API_KEY,
  //   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  //   databaseURL: process.env.REACT_APP_DATABASE_URL,
  //   projectId: process.env.REACT_APP_PROJECT_ID,
  //   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  //   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  //   appId: process.env.REACT_APP_APP_ID,
  //   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  // };

  try {
    firebase.initializeApp(firebaseConfig);
  } catch (error) {
    console.log({error});
    throw new Error(error.message);
  }
};