import React from 'react';
import styled from 'styled-components';
import NavigationAuthList from "./NavigationAuthList";
import NavigationNonAuthList from "./NavigationNonAuthList";

const NavBar = styled.nav`
  height: 100%;
  background-color: #676767;
  box-shadow: 1px 0 7px rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 350px;
  z-index: 200;
  transform: ${(props => props.show? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-out;
  
  ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  li {
    margin: 0.5rem 0;
  }
  
  button {
    padding: 0;
    border: none;
    background: none;
    :focus {
      outline: none;
    }
  }
  
  a, button {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 1.2rem;
  }
  
  a:hover, a:active, button:hover, button:active {
    color: #fa923f
  }
  
  @media (min-width: 769px){
    display: none;
  }
`;

const SideDrawer = props => {
  const {authUser, show} = props;
  return (
    <NavBar show={show}>
      {authUser ? <NavigationAuthList/> : <NavigationNonAuthList/>}
    </NavBar>
  )
};

export default SideDrawer;
