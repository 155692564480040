import React, {Fragment, useState} from 'react';
import DefaultModal from "../components/DefaultModal";
import styled from "styled-components";
import ClinicForm from "./ClinicForm";

const NewClinicButton = styled.button`
  background: transparent;
  border-style: solid;
  border-color: #2980b9;
  border-width: 1px;
  border-radius: 5px;
  :hover, :active {
    background: #2980b9;
    color: #FFFFFF;
  }
`;

const Admin = () => {
  const [showModal, setShowModal] = useState(false);

  const openModalHandler = () => {
    setShowModal(true);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <DefaultModal
        isOpen={showModal}
        onRequestClose={closeModalHandler}
        title={"Teste"}
        children={<ClinicForm closeModalHandler={closeModalHandler} formFieldsRef={{}}/>}
      />

      <h1>Locais</h1>
      <NewClinicButton onClick={openModalHandler}>+ info</NewClinicButton>
    </Fragment>
  )
};

export default Admin;


