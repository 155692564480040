import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {SignUpLink} from './SignUp';
import {fireAuth} from '../firebase';
import * as ROUTES from '../constants/routes';
import {PasswordForgetLink} from "./PasswordForget";
import FormContainer from "../components/FormContainer";

const SignInPage = () => (
  <FormContainer>
    <h1>Login</h1>
    <SignInForm/>
    <PasswordForgetLink/>
    <SignUpLink/>
  </FormContainer>
);

const SignInFormBase = (props) => {
  const [formFields, setFormFields] = useState({});

  const onSubmit = async event => {
    event.preventDefault();

    try {
      await fireAuth().signInWithEmailAndPassword(formFields.email, formFields.password);
      setFormFields({});
      props.history.push(ROUTES.MAP);
    } catch (signInError) {
      setFormFields({...formFields, error: signInError});
      console.log({signInError});
    }
  };

  const onChange = event => {
    setFormFields({...formFields, [event.target.name]: event.target.value});
  };

  const isInvalid = formFields.password === '' || formFields.email === '';
  return (
    <form onSubmit={onSubmit}>
      <input
        name="email"
        value={formFields.email}
        onChange={onChange}
        type="text"
        placeholder="E-mail"
      />
      <input
        name="password"
        value={formFields.password}
        onChange={onChange}
        type="password"
        placeholder="Senha"
      />
      <button disabled={isInvalid} type="submit">
        Logar
      </button>
      {formFields.error && <p>{formFields.error.message}</p>}
    </form>
  );
};

const SignInForm = withRouter(SignInFormBase);
export default SignInPage;
export {SignInForm};