import React, {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import SignOutButton from "../containers/SignOut";


const NavigationAuthList = () => (
  <Fragment>
    <ul>
      <li>
        <Link to={ROUTES.ADMIN}>Administração</Link>
      </li>
      <li>
        <SignOutButton/>
      </li>
    </ul>
  </Fragment>
);

export default withRouter(NavigationAuthList);
