import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #e0e0e0;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Content = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  width: 30%;
  bottom: auto;
  transform: translate(-50%, -50%);
  color: #000000;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input {
    margin: 0.5rem;
  }
`;

const FormContainer = props => {
  const {children} = props;
  return (
    <Container>
      <Content>
        {children}
      </Content>
    </Container>
  )
};

export default FormContainer;
