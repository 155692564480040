import React from 'react';
import {fireAuth} from '../firebase';

const SignOutButton = () => {
  const signOut = async () => {
    try {
      await fireAuth().signOut()
    } catch (signOutError) {
      console.log(signOutError);
    }
  };

  return (
    <button type="button" onClick={async () => signOut()}>
      Sair
    </button>
  )
};

export default SignOutButton;