import createBrowserHistory from 'history/createBrowserHistory';
import React, {useEffect, useState} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {Provider as AlertProvider} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import * as ROUTES from './constants/routes';
import styled from "styled-components";
import {fireAuth} from "./firebase";

import PrivateRoute from "./PrivateRoute";
import Navigation from "./components/Navigation";
import Backdrop from "./components/Backdrop";
import SideDrawer from "./components/SideDrawer";
import HomePage from './containers/HomePage';
import SignUpPage from './containers/SignUp';
import SignInPage from './containers/SignIn';
import PasswordForgetPage from './containers/PasswordForget';
import Admin from './containers/Admin';

const Container = styled.div`
  z-index: 1;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  position: absolute;
  top: ${props => props.navHeight};
  bottom: 0;
`;

const history = createBrowserHistory();

const options = {
  position: 'top center', timeout: 5000, offset: '30px', transition: 'fade',
};

const App = () => {
  const [authUser, setAuthUser] = useState(null);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);

  useEffect(() => {
    return fireAuth().onAuthStateChanged(_authUser => {
      setAuthUser(_authUser ? _authUser : null);
    });
  }, []);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(prevState => !prevState);
    setShowBackdrop(prevState => !prevState);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
    setShowBackdrop(false);
  };

  const navHeight = '56px';

  const backdrop = showBackdrop ? <Backdrop click={backdropClickHandler}/> : null;

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Router history={history} style={{height: '100%'}}>
        <Navigation authUser={authUser} navHeight={navHeight} drawerClickHandler={drawerToggleClickHandler}/>
        <SideDrawer authUser={authUser} show={sideDrawerOpen}/>
        {backdrop}
        <Container>
          <Content navHeight={navHeight}>
            <Switch>
              <Route exact path={ROUTES.MAP} component={HomePage}/>
              <Route exact path={ROUTES.SIGN_UP} component={SignUpPage}/>
              <Route exact path={ROUTES.SIGN_IN} component={SignInPage}/>
              <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
              <PrivateRoute exact path={ROUTES.ADMIN} component={Admin} authUser={authUser}/>
            </Switch>
          </Content>
        </Container>
      </Router>
    </AlertProvider>
  )
};

export default App;