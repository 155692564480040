import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ABBREVIATED_DAYS} from "../constants/utils";

const Card = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2.5rem;
`;

const TitleBox = styled.div`
  color: #2980b9;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const CloseButton = styled.button`
  background: transparent;
  border-style: solid;
  border-color: #2980b9;
  border-width: 1px;
  border-radius: 5px;
  :hover, :active {
    background: #2980b9;
    color: #FFFFFF;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const Avatar = styled.div`
  position: relative;
  width: 100px;
  height: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  img{
    width: 100px;
    height: auto;
  }
`;

const TextBox = styled.div`
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 10px;

  p {
    text-align: left;
    color: #aaa;
  }
`;

const CardItemDetail = props => {
  const {clinic, images, genBusinessHoursText, closeModalHandler} = props;
  const {name, phones, price, specializations, address} = clinic;

  const [specializationText, setSpecializationText] = useState(null);

  useEffect(() => {
    Promise.all(specializations.map(async (specialization, index) => {
      const brAtEnd = (specializations.length === index) ? '' : <br/>;
      const _specialization = await specialization.get();
      return (
        <Fragment key={index}>
          {` • ${_specialization.data().name}.`}
          {brAtEnd}
        </Fragment>
      )
    }))
      .then(result => setSpecializationText(result));
  }, []);

  return (
    <Card>
      <TitleBox>
        <h1>{name}</h1>
        <Spacer/>
        <CloseButton onClick={closeModalHandler}>X</CloseButton>
      </TitleBox>
      <Content>
        <Avatar> <img src={images[0]} alt={name}/> </Avatar>
        <TextBox>
          <p>
            <b>Telefone:</b> {phones.map(phone => (`(${phone.ddd}) ${phone.number}. `))} <br/>
            <b>Horário de atendimento:</b> <br/> {genBusinessHoursText()}
            <b>Valor consulta:</b> {price} <br/>
            <b>Endereço:</b> {`${address.street}, nº${address.number} - ${address.complement} - ${address.neighborhood} - ${address.city} - ${address.state}. CEP: ${address.zipCode}`}
            <br/>
            <b>Especializações:</b> <br/> {specializationText}
          </p>
        </TextBox>
      </Content>
    </Card>
  )
};

export default CardItemDetail;
