import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

Modal.setAppElement('#render-target');

const defaultStyle = {
  content: {
    top: '50%',
    left: '50%',
    width: '30%',
    minWidth: '800px',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    color: '#000000',
    minHeight: '85%',
    maxHeight: '85%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: '2'
  },
};

const DefaultModal = (props) => {
  const {style, isOpen, onRequestClose, title, children} = props;

  const customStyle = style || {};
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
      style={{...defaultStyle, ...customStyle}}
      contentLabel={title}
    >
      {children}
    </Modal>
  );
};

DefaultModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
  onRequestClose: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  subtitle: PropTypes.string,
};

export default DefaultModal;
