import styled from "styled-components";
import React, {Fragment, useState} from "react";

const TitleBox = styled.div`
  color: #2980b9;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const CloseButton = styled.button`
  background: transparent;
  border-style: solid;
  border-color: #2980b9;
  border-width: 1px;
  border-radius: 5px;
  :hover, :active {
    background: #2980b9;
    color: #FFFFFF;
  }
`;

const FormBox = styled.form`

  //display: flex;
  //flex-direction: column;
  input, textarea {
    margin: 1rem 0.5rem;
    ::placeholder {
      color: rgba(0,0,0,0.3);
    }
    
  }
  
  div {
    display: flex;
    //flex-direction: row;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0,0,0,0.3);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }
  
  button {
    float: right;
  }
`;

const HeaderBox = styled.div`
  input {
    width: 48.6%;
  }
`;

const AddressBox = styled.div`
  input {
    width: 31.9%;
  }
`;

const GeoPointBox = styled.div`
  input {
    width: 48.6%;
  }
`;

const BusinessHoursBox = styled.div`

`;

const PhonesBox = styled.div`
  input {
    width: 48.6%;
  }
`;

const PriceBox = styled.div`
  textarea {
    width: 100%;
  }
`;

const ClinicForm = props => {
  const {closeModalHandler, formFieldsRef} = props;

  const [formFields, setFormFields] = useState({...formFieldsRef});

  const onSubmit = async event => {
    event.preventDefault();
    try {
      console.log(formFields)
    } catch (passwordResetError) {
      setFormFields({...formFields, error: passwordResetError});
    }
  };

  const onChange = event => {
    setFormFields({...formFields, [event.target.name]: event.target.value});
  };

  let isInvalid = !formFields.name ||
    !formFields.locality ||
    !formFields.zipCode ||
    !formFields.street ||
    !formFields.number ||
    !formFields.complement ||
    !formFields.neighborhood ||
    !formFields.city ||
    !formFields.state ||
    !formFields.lat ||
    !formFields.lng ||
    !formFields.dayStart ||
    !formFields.dayEnd ||
    !formFields.hourStart ||
    !formFields.hourEnd ||
    !formFields.phoneDdd ||
    !formFields.phoneNumber ||
    !formFields.price;

  isInvalid = false;

  return (
    <Fragment>
      <TitleBox>
        <h1>Novo local</h1>
        <Spacer/>
        <CloseButton onClick={closeModalHandler}>X</CloseButton>
      </TitleBox>

      <FormBox onSubmit={onSubmit}>
        <input hidden name="id" value={formFields.id} onChange={onChange} type="text" placeholder="ID"/>

        <HeaderBox>
          <input name="name" value={formFields.name} onChange={onChange} type="text" placeholder="Nome"/>
          <input name="locality" value={formFields.locality} onChange={onChange} type="text" placeholder="Descrição Localidade"/>
        </HeaderBox>

        <AddressBox>
          <input name="zipCode" value={formFields.zipCode} onChange={onChange} type="text" placeholder="CEP"/>
          <input name="street" value={formFields.street} onChange={onChange} type="text" placeholder="Logradouro"/>
          <input name="number" value={formFields.number} onChange={onChange} type="text" placeholder="Número"/>
          <input name="complement" value={formFields.complement} onChange={onChange} type="text" placeholder="Complemento"/>
          <input name="neighborhood" value={formFields.neighborhood} onChange={onChange} type="text" placeholder="Bairro"/>
          <input name="city" value={formFields.city} onChange={onChange} type="text" placeholder="Cidade"/>
          <input name="state" value={formFields.state} onChange={onChange} type="text" placeholder="Estado"/>
        </AddressBox>

        <GeoPointBox>
          <input name="lat" value={formFields.lat} onChange={onChange} type="text" placeholder="Latitude"/>
          <input name="lng" value={formFields.lng} onChange={onChange} type="text" placeholder="Longitude"/>
        </GeoPointBox>

        <BusinessHoursBox>
          <input name="dayStart" value={formFields.dayStart} onChange={onChange} type="text" placeholder="Dia Inicial Funcionamento"/>
          <input name="dayEnd" value={formFields.dayEnd} onChange={onChange} type="text" placeholder="Dia Final Funcionamento"/>
          <input name="hourStart" value={formFields.hourStart} onChange={onChange} type="text" placeholder="Hora de abertura"/>
          <input name="hourEnd" value={formFields.hourEnd} onChange={onChange} type="text" placeholder="Hora de encerramento"/>
        </BusinessHoursBox>

        <PhonesBox>
          <input name="phoneDdd" value={formFields.phoneDdd} onChange={onChange} type="text" placeholder="DDD"/>
          <input name="phoneNumber" value={formFields.phoneNumber} onChange={onChange} type="text" placeholder="Número Telefone"/>
        </PhonesBox>

        <PriceBox>
          <textarea name="price" value={formFields.price} onChange={onChange} placeholder="Preço"/>
        </PriceBox>

        <button disabled={isInvalid} type="submit">
          {formFieldsRef && formFieldsRef.id ? "Editar" : "Adicionar"}
        </button>
        {formFields.error && <p>{formFields.error.message}</p>}
      </FormBox>
    </Fragment>
  );

};

export default ClinicForm;


