import React from "react";
import styled from 'styled-components';

const BackdropDiv = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 100;
  top: 0;
  left: 0;
`;

const Backdrop = props => {
  const {click} = props;
  return <BackdropDiv onClick={click}/>
};

export default Backdrop;