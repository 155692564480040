import React, {useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {fireAuth} from '../firebase';
import * as ROUTES from '../constants/routes';
import FormContainer from "../components/FormContainer";

const SignUpPage = () => (
  <FormContainer>
    <h1>SignUp</h1>
    <SignUpForm/>
  </FormContainer>
);

const SignUpFormBase = (props) => {
  const [formFields, setFormFields] = useState({});

  const onSubmit = async event => {
    event.preventDefault();

    try {
      await fireAuth().createUserWithEmailAndPassword(formFields.email, formFields.passwordOne);
      setFormFields({});
      props.history.push(ROUTES.MAP);
    } catch (createUserError) {
      setFormFields({...formFields, error: createUserError});
    }
  };

  const onChange = event => {
    setFormFields({...formFields, [event.target.name]: event.target.value});
  };

  const isInvalid =
    formFields.passwordOne !== formFields.passwordTwo ||
    formFields.passwordOne === '' ||
    formFields.email === '' ||
    formFields.username === '';

  return (
    <form onSubmit={onSubmit}>
      <input
        name="username"
        value={formFields.username}
        onChange={onChange}
        type="text"
        placeholder="Full Name"
      />
      <input
        name="email"
        value={formFields.email}
        onChange={onChange}
        type="text"
        placeholder="Email Address"
      />
      <input
        name="passwordOne"
        value={formFields.passwordOne}
        onChange={onChange}
        type="password"
        placeholder="Password"
      />
      <input
        name="passwordTwo"
        value={formFields.passwordTwo}
        onChange={onChange}
        type="password"
        placeholder="Confirm Password"
      />
      <button disabled={isInvalid} type="submit">
        Sign Up
      </button>
      {formFields.error && <p>{formFields.error.message}</p>}
    </form>
  );
};

const SignUpLink = () => (
  <p>
    Ainda não tem uma conta? <Link to={ROUTES.SIGN_UP}>Crie agora</Link>
  </p>
);

const SignUpForm = withRouter(SignUpFormBase);

export default SignUpPage;
export {SignUpForm, SignUpLink};