import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {Route, withRouter} from 'react-router-dom';
import * as ROUTES from './constants/routes';

const PrivateRoute = ({component, path, authUser, history}) => {
  if (!authUser) {
    history.push(ROUTES.MAP);
  } else {
    return <Route exact path={path} component={component}/>;
  }

  return <Fragment/>
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  authUser: PropTypes.object,
  history: PropTypes.any,
};

export default withRouter(PrivateRoute);
