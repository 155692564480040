import React, {useState, useEffect} from 'react';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import {fireStore} from '../firebase';
import Map from "../components/Map";
import CardList from "../components/CardList";

// const MemoMap = useCallback(<Map/>, []);

const HomePage = () => {
  const [clinics, setClinics] = useState([]);

  const clinicsRef = fireStore().collection('clinics');

  useEffect(() => {
    return clinicsRef.onSnapshot(
      snapshot => {
        const _clinics = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setClinics(_clinics);
      },
      err => {
        console.log(err);
      }
    );
  }, []);

  return (
    <SplitPane split="vertical">
      <Pane minSize="30%" initialSize="40%">
        <CardList clinics={clinics}/>
      </Pane>
      <Pane minSize="30%" initialSize="60%">
        <Map markers={clinics}/>
      </Pane>
    </SplitPane>
  )
};

export default HomePage;