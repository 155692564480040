import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import * as ROUTES from '../constants/routes';
import NavigationAuthList from "./NavigationAuthList";
import NavigationNonAuthList from "./NavigationNonAuthList";
import DrawerToggleButton from "./DrawerToggleButton";

const NavBar = styled.header`
  width: 100%;
  position: fixed;
  background-color: #676767;
  height: ${props => props.navHeight};
  top: 0;
  left: 0;
`;

const Toolbar = styled.nav`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
`;

const Logo = styled.div`
  margin-left: 1rem;
  
  @media (min-width: 769px){
    margin-left: 0;
  }

  img {
    height: 70%;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const Items = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  li {
  padding: 0 0.5rem;
  }
  
  button {
    padding: 0;
    border: none;
    background: none;
    :focus {
      outline: none;
    }
  }

  a, button {
    color: #FFFFFF;
    text-decoration: none;
  }
  
  a:hover, a:active, button:hover, button:active {
    color: #fa923f
  }
  
  @media (max-width: 768px){
    display: none;
  }
`;

const ToggleButton = styled.div`
  @media (min-width: 769px){
    display: none;
  }
`;

const Navigation = (props) => {
  const {authUser, navHeight, drawerClickHandler} = props;
  return (
    <NavBar navHeight={navHeight}>
      <Toolbar>
        <ToggleButton>
          <DrawerToggleButton click={drawerClickHandler}/>
        </ToggleButton>
        <Logo><Link to={ROUTES.MAP}><img src="/images/logo.png" alt="SUA VIDA IMPORTA"/></Link></Logo>
        <Spacer/>
        <Items>
          {authUser ? <NavigationAuthList/> : <NavigationNonAuthList/>}
        </Items>
      </Toolbar>
    </NavBar>
  )
};


export default withRouter(Navigation);
